<template>
  <div class="row">
    <div class="col-12">
      <ibox
        :title="this.$t('nav.training-dates-list')"
        :collapseId="'collapse-2'"
      >
        <template slot="tools">
          <button class="btn btn-danger" @click="remove">Usuń</button>
        </template>
        <template slot="content" slot-scope="{ setLoading }">
          <TrainingTrainingsForm @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import TrainingTrainingsForm from "@/components/Courses/TrainingTrainingsForm.vue";

export default {
  name: "TrainingTrainingsDetails",
  components: {
    Ibox,
    TrainingTrainingsForm
  },
  data() {
    return {
      excel: true
    };
  },
  methods: {
    remove() {
      if (confirm("Na pewno chcesz usunąć szkolenie?") == true) {
        let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/training-trainings/${this.$route.params.id}`;

        this.$http
          .customRequest({
            method: "delete",
            url: url
          })
          .then(() => {
            // console.log(resp);
            this.$toastr.success("Zrobione!");
          })
          .catch(err => {
            this.$toastr.error(err);
          });
      }
    }
  }
};
</script>
