<template>
  <div class="row">
    <div class="col-12 col-md-4 b-r">
      <form role="form">
        <div class="form-group">
          <label>Link</label>
          <input
            type="text"
            class="form-control"
            :value="seo.link"
            name="seo_link"
            :disabled="'disabled'"
            v-validate="'required'"
          />
          <small v-show="errors.has('seo_link')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Tytuł</label>
          <input
            type="text"
            class="form-control"
            v-model="seo.title"
            name="seo_title"
            @blur="setLink()"
            v-validate="'required'"
          />
          <small v-show="errors.has('seo_title')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Opis</label>
          <textarea
            type="text"
            class="form-control"
            v-model="seo.description"
            name="seo_description"
            v-validate="'required'"
          ></textarea>
          <small v-show="errors.has('seo_description')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Słowa kluczowe</label>
          <textarea
            type="text"
            class="form-control"
            v-model="seo.keywords"
            name="seo_keywords"
            v-validate="'required'"
          ></textarea>
          <small v-show="errors.has('seo_keywords')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
      </form>
    </div>
    <div class="col-12 col-md-4 b-r">
      <form role="form">
        <div class="form-group">
          <label>Nazwa</label>
          <input
            type="text"
            class="form-control"
            v-model="training.name"
            name="training_name"
            v-validate="'required'"
          />
          <small v-show="errors.has('training_name')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Kategoria</label>
          <select
            class="form-control"
            name="training_category_id"
            v-validate="'required'"
            v-model="training.category_id"
            autocomplete="off"
          >
            <option v-for="item in categories" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <small
            v-show="errors.has('training_category_id')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>CENA SZKOLENIA W GRUPIE</label>
          <input
            type="number"
            class="form-control"
            v-model="training.price_group"
            name="training_price_group"
            v-validate="'required'"
          />
          <small
            v-show="errors.has('training_price_group')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>CENA SZKOLENIA INDYWIDUALNEGO</label>
          <input
            type="number"
            class="form-control"
            v-model="training.price_individual"
            name="training_price_individual"
            v-validate="'required'"
          />
          <small
            v-show="errors.has('training_price_individual')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Start szkolenia</label>
          <input
            type="text"
            class="form-control"
            v-model="training.hours_start"
            name="training_hours_start"
            v-validate="'required'"
          />
          <small
            v-show="errors.has('training_hours_start')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Koniec szkolenia</label>
          <input
            type="text"
            class="form-control"
            v-model="training.hours_end"
            name="training_hours_end"
            v-validate="'required'"
          />
          <small v-show="errors.has('training_hours_end')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Ilość godziń całego szkolenia</label>
          <input
            type="number"
            class="form-control"
            v-model="training.hours"
            name="training_hours"
            v-validate="'required|integer'"
          />
          <small v-show="errors.has('training_hours')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Grupa min</label>
          <input
            type="number"
            class="form-control"
            v-model="training.group_count_min"
            name="training_group_count_min"
            v-validate="'required'"
          />
          <small
            v-show="errors.has('training_group_count_min')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Grupa max</label>
          <input
            type="number"
            class="form-control"
            v-model="training.group_count_max"
            name="training_group_count_max"
            v-validate="'required'"
          />
          <small
            v-show="errors.has('training_group_count_max')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <!-- <div class="form-group">
          <label>Zdjęcie w tle</label>
          <input
            type="text"
            class="form-control"
            v-model="training.photo_background"
            name="training_photo_background"
          />
          <small
            v-show="errors.has('training_photo_background')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <label>Zdjęcie miniatura</label>
          <input
            type="text"
            class="form-control"
            v-model="training.photo_miniature"
            name="training_photo_miniature"
          />
          <small
            v-show="errors.has('training_photo_miniature')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div> -->
        <div class="form-group">
          <!-- <label>Zdjęcie w tle</label> -->
          <!-- <input
            type="text"
            class="form-control"
            v-model="training.photo_background"
            name="training_photo_background"
          /> -->
          <input-file
            ref="inputPhotoBackground"
            :inputData.sync="training.photo_background"
            :prefix="`img/trainings`"
            label="Zdjęcie w tle"
            :urlUploadFile="urlUpdateFile()"
            name="training_photo_background"
            @loading="updateFileLoading"
            :required="true"
          />
          <small
            v-show="errors.has('training_photo_background')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
        <div class="form-group">
          <!-- <label>Zdjęcie miniatura</label> -->
          <!-- <input
            type="text"
            class="form-control"
            v-model="training.photo_miniature"
            name="training_photo_miniature"
          /> -->
          <input-file
            ref="inputPhotoMiniature"
            :inputData.sync="training.photo_miniature"
            :prefix="`img/trainings`"
            label="Zdjęcie miniatura"
            :urlUploadFile="urlUpdateFile()"
            name="training_photo_miniature"
            @loading="updateFileLoading"
            :required="true"
          />
          <small
            v-show="errors.has('training_photo_miniature')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
      </form>
    </div>
    <div class="col-12 col-md-4">
      <h3 class="m-t-none m-b">Program szkolenia</h3>
      <!-- {{ training.content.description }} -->
      <div class="form-group">
        <textarea
          class="form-control"
          v-model="training.description"
          name="training_content_description"
        ></textarea>
        <small
          v-show="errors.has('training_content_description')"
          class="text-danger"
        >
          Pole wymagane!
        </small>
      </div>
      <div class="hr-line-dashed"></div>
      <h3 class="m-t-none m-b">Część teoretyczna</h3>
      <!-- {{ training.content.training_program.theory }} -->
      <ol>
        <li
          v-for="(theory, i) in training.content.training_program.theory"
          :key="`theory${i}`"
        >
          <!-- {{ theory.value }} -->
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="theory.value"
              :name="`theory_value_${i}`"
            />
            <small v-show="errors.has(`theory_value_${i}`)" class="text-danger">
              Pole wymagane!
            </small>
          </div>
        </li>
      </ol>
      <p
        class="text-info"
        @click="addValue(training.content.training_program.theory)"
      >
        <i class="fa fa-plus"></i> {{ $t.add }}
      </p>
      <div class="hr-line-dashed"></div>
      <h3 class="m-t-none m-b">Część praktyczna</h3>
      <!-- {{ training.content.training_program.practice }} -->
      <ol>
        <li
          v-for="(practice, i) in training.content.training_program.practice"
          :key="`practice${i}`"
        >
          <!-- {{ practice.value }} -->
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="practice.value"
              :name="`practice_value_${i}`"
            />
            <small
              v-show="errors.has(`practice_value_${i}`)"
              class="text-danger"
            >
              Pole wymagane!
            </small>
          </div>
        </li>
      </ol>
      <p
        class="text-info"
        @click="addValue(training.content.training_program.practice)"
      >
        <i class="fa fa-plus"></i> {{ $t.add }}
      </p>
      <div class="hr-line-dashed"></div>
      <h3 class="m-t-none m-b">Dodatkowe informacje</h3>
      <!-- {{ training.content.bonus_info }} -->
      <ol>
        <li
          v-for="(bonus_info, i) in training.content.bonus_info"
          :key="`bonus_info${i}`"
        >
          <!-- {{ bonus_info.value }} -->
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="bonus_info.value"
              :name="`bonus_info_value_${i}`"
            />
            <small
              v-show="errors.has(`bonus_info_value_${i}`)"
              class="text-danger"
            >
              Pole wymagane!
            </small>
          </div>
        </li>
      </ol>
      <p class="text-info" @click="addValue(training.content.bonus_info)">
        <i class="fa fa-plus"></i> {{ $t.add }}
      </p>
      <div class="hr-line-dashed"></div>
      <h3 class="m-t-none m-b">Notatka</h3>
      <textarea
        class="form-control"
        type="text"
        name="note"
        size="1000"
        v-model="training.note"
        autocomplete="off"
      ></textarea>
      <div class="hr-line-dashed"></div>
      <h3 class="m-t-none m-b">Naszym kursantom oferujemy</h3>
      <!-- {{ training.content.student_discount }} -->
      <ol>
        <li
          v-for="(student_discount, i) in training.content.student_discount"
          :key="`student_discount${i}`"
        >
          <!-- {{ student_discount.value }} -->
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="student_discount.value"
              :name="`student_discount_value_${i}`"
            />
            <small
              v-show="errors.has(`student_discount_value_${i}`)"
              class="text-danger"
            >
              Pole wymagane!
            </small>
          </div>
        </li>
      </ol>
      <p class="text-info" @click="addValue(training.content.student_discount)">
        <i class="fa fa-plus"></i> {{ $t.add }}
      </p>
      <div class="hr-line-dashed"></div>
    </div>
    <div class="col-12">
      <div @click="saveChange" class="btn btn-primary">Zapisz</div>
    </div>
  </div>
</template>

<script>
// import { php } from "locutus";
import InputFile from "@/components/base/InputFile.vue";

export default {
  name: "TrainingTrainingsForm",
  components: {
    InputFile,
  },
  data() {
    return {
      editMode: false,
      categories: [],
      seo: {
        description: "",
        json_ld: ".",
        keywords: "",
        link: "",
        title: "",
      },
      training: {
        description: "",
        note: "",
        category_id: null,
        content: {
          bonus_info: [],
          description: "",
          note: "",
          student_discount: [],
          training_program: {
            practice: [],
            theory: [],
          },
        },
        group_count_max: null,
        group_count_min: null,
        hours: null,
        hours_end: "",
        hours_start: "",
        name: "",
        photo_background: "",
        photo_miniature: "",
        price_group: null,
        price_individual: null,
        seo_id: null,
        sort: 1,
      },
    };
  },
  async created() {
    this.$emit("loading", true);
    await this.getCategories();
    if (this.$route.params.id != undefined) {
      this.editMode = true;
      await this.getTrainingData();
      await this.getSeoData(this.training.seo_id);
    }
    this.$emit("loading", false);
  },
  methods: {
    urlUpdateFile() {
      return `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/file`;
    },
    updateFileLoading(loading) {
      this.$emit("loading", loading);
    },
    addValue(value) {
      value.push({ id: null, value: "" });
    },
    async getCategories() {
      this.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/training-category`;
      return await this.$http
        .get(url)
        .then((resp) => {
          this.categories = resp.data;
        })
        .catch((err) => {
          this.$toastr.error(err);
        });
    },
    async getTrainingData() {
      if (this.$route.params.id != undefined) {
        this.$emit("loading", true);
        let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/training-trainings/${this.$route.params.id}`;
        return await this.$http
          .get(url)
          .then((resp) => {
            this.training = resp.data;
            // this.training.content = php.var.unserialize(this.training.content);
            this.$emit("loading", false);
          })
          .catch((err) => {
            this.$toastr.error(err);
            this.$emit("loading", false);
          });
      }
      this.$emit("loading", false);
    },
    getSeoData(id) {
      this.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/seo/${id}`;
      this.$http
        .get(url)
        .then((resp) => {
          this.seo = resp.data;
          this.$emit("loading", false);
        })
        .catch((err) => {
          this.$toastr.error(err);
          this.$emit("loading", false);
        });
      this.$emit("loading", false);
    },
    async saveChange() {
      console.log(this.$validator);
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$emit("loading", true);
          await this.saveSeo().then(async (res) => {
            console.log("res", res);
            if (res !== false) {
              this.training.seo_id = res.data.id;
              await this.saveTraining();
            }
          });
          this.$emit("loading", false);
        }
      });
    },
    async saveSeo() {
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/seo`;

      return await this.$http
        .customRequest({
          method: this.$route.params.id !== undefined ? "put" : "post",
          url:
            this.$route.params.id !== undefined ? `${url}/${this.seo.id}` : url,
          data: this.seo,
        })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          this.$toastr.error(err);
          return false;
        });
    },
    saveTraining() {
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/training-trainings`;
      let training = this.training;
      // training.content = php.var.serialize(training.content);
      this.$http
        .customRequest({
          method: this.$route.params.id !== undefined ? "put" : "post",
          url:
            this.$route.params.id !== undefined
              ? `${url}/${this.$route.params.id}`
              : url,
          data: training,
        })
        .then(() => {
          this.$emit("loading", false);
          this.$toastr.success("Zrobione!");
          this.$router.push(
            `/${this.$route.params.shop_lang}/${this.$route.params.shop_name}/courses/training-trainings`
          );
        })
        .catch((err) => {
          this.$toastr.error(err);
          return false;
        });
    },

    setLink() {
      if (this.editMode == false) {
        this.seo.link = this.seo.title
          .toLowerCase()
          .replaceAll("ł", "l")
          .replaceAll(" ", "-")
          .normalize("NFKD")
          .replace(/[\u0300-\u036F]/g, "");
      }
    },
  },
};
</script>
